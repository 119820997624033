exports.components = {
  "component---src-ui-pages-404-tsx": () => import("./../../../src/ui/pages/404.tsx" /* webpackChunkName: "component---src-ui-pages-404-tsx" */),
  "component---src-ui-pages-blog-contentful-article-slug-tsx": () => import("./../../../src/ui/pages/blog/{ContentfulArticle.slug}.tsx" /* webpackChunkName: "component---src-ui-pages-blog-contentful-article-slug-tsx" */),
  "component---src-ui-pages-blog-index-tsx": () => import("./../../../src/ui/pages/blog/index.tsx" /* webpackChunkName: "component---src-ui-pages-blog-index-tsx" */),
  "component---src-ui-pages-contact-tsx": () => import("./../../../src/ui/pages/contact.tsx" /* webpackChunkName: "component---src-ui-pages-contact-tsx" */),
  "component---src-ui-pages-contentful-basic-page-slug-tsx": () => import("./../../../src/ui/pages/{ContentfulBasicPage.slug}.tsx" /* webpackChunkName: "component---src-ui-pages-contentful-basic-page-slug-tsx" */),
  "component---src-ui-pages-faq-tsx": () => import("./../../../src/ui/pages/faq.tsx" /* webpackChunkName: "component---src-ui-pages-faq-tsx" */),
  "component---src-ui-pages-index-tsx": () => import("./../../../src/ui/pages/index.tsx" /* webpackChunkName: "component---src-ui-pages-index-tsx" */),
  "component---src-ui-pages-media-tsx": () => import("./../../../src/ui/pages/media.tsx" /* webpackChunkName: "component---src-ui-pages-media-tsx" */),
  "component---src-ui-pages-movies-contentful-movie-slug-tsx": () => import("./../../../src/ui/pages/movies/{ContentfulMovie.slug}.tsx" /* webpackChunkName: "component---src-ui-pages-movies-contentful-movie-slug-tsx" */),
  "component---src-ui-pages-movies-index-tsx": () => import("./../../../src/ui/pages/movies/index.tsx" /* webpackChunkName: "component---src-ui-pages-movies-index-tsx" */),
  "component---src-ui-pages-press-releases-contentful-press-release-slug-tsx": () => import("./../../../src/ui/pages/press-releases/{ContentfulPressRelease.slug}.tsx" /* webpackChunkName: "component---src-ui-pages-press-releases-contentful-press-release-slug-tsx" */),
  "component---src-ui-pages-shows-contentful-show-slug-tsx": () => import("./../../../src/ui/pages/shows/{ContentfulShow.slug}.tsx" /* webpackChunkName: "component---src-ui-pages-shows-contentful-show-slug-tsx" */),
  "component---src-ui-pages-watch-contentful-flick-slug-tsx": () => import("./../../../src/ui/pages/watch/{ContentfulFlick.slug}.tsx" /* webpackChunkName: "component---src-ui-pages-watch-contentful-flick-slug-tsx" */)
}

