type SupportedLocale = "en" | "es"

export interface Locale {
  name: SupportedLocale
  locale: string
}

export const locales: Record<string, Locale> = {
  es: {
    name: `es`,
    locale: `Español`,
  },
  en: {
    name: `en`,
    locale: `English`,
  },
}

export type LocaleKey = keyof typeof locales

export const defaultLocale: LocaleKey = `en`

export const localePath = (path: string, locale?: LocaleKey) =>
  !locale || locale === defaultLocale ? path : `/${locales[locale].name}${path}`

export const basePath = (path: string, locale?: LocaleKey) =>
  !locale || locale === defaultLocale ? path : path.replace(new RegExp(`^/${locales[locale].name}`), ``)
