import React from "react"
import { GatsbyBrowser } from "gatsby"

import InBrowserContext from "./src/ui/contexts/in-browser"
import { I18nProvider } from "./src/ui/contexts/i18n"

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({ element }) => {
  return <InBrowserContext.Provider value={true}>{element}</InBrowserContext.Provider>
}

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({ props: { pageContext }, element }) => {
  const locale = typeof pageContext?.locale === "string" ? pageContext.locale : "en"

  return <I18nProvider value={locale}>{element}</I18nProvider>
}
