import React, { useContext } from "react"
import { Link, navigate } from "gatsby"

import { locales, localePath, LocaleKey, Locale } from "../../locales"

// @ts-ignore
import DATA from "../../data/locales.json"

type Group = keyof typeof DATA & string

/**
 * LocaleLink Component
 */

interface LocaleLinkProps extends Omit<React.ComponentProps<typeof Link>, "ref"> {
  to: string
}

export function LocaleLink({ to, ...props }: LocaleLinkProps) {
  const { locale } = useI18n()
  const localeTo = localePath(to, locale?.name)

  // @ts-ignore
  return <Link {...props} to={localeTo} />
}

/**
 * Context
 */

function localeNavigate(locale: Locale | null, to: string, opts?: { replace: boolean }) {
  return navigate(localePath(to, locale?.name), opts)
}

interface I18nContext {
  locale: Locale | null
  navigate: (to: string, opts?: { replace: boolean }) => void
}

const I18nContext = React.createContext<I18nContext>({
  locale: null,
  navigate(to, opts) {
    return localeNavigate(this.locale, to, opts)
  },
})

interface I18nProviderProps {
  value: LocaleKey
  children: React.ReactNode
}

export function I18nProvider({ value: name, children }: I18nProviderProps) {
  return (
    <I18nContext.Provider
      value={{
        locale: locales[name],
        navigate: (to, opts) => localeNavigate(locales[name], to, opts),
      }}
    >
      {children}
    </I18nContext.Provider>
  )
}

interface Translate {
  (strs: TemplateStringsArray): string
  (strs: TemplateStringsArray, ...expressions: string[]): string
  (strs: TemplateStringsArray, ...expressions: React.ReactNode[]): React.ReactNode
}

function isStringArray(arr: unknown[]): arr is string[] {
  return arr.every((str) => typeof str === "string")
}

export function useI18n(): I18nContext
export function useI18n(group: Group): I18nContext & { translate: Translate }
export function useI18n(group?: Group): I18nContext & { translate?: Translate } {
  const context = useContext(I18nContext)

  return !group
    ? context
    : {
        ...context,
        translate: (strs, ...expressions) => {
          if (expressions.length === 0) return t(strs[0])

          if (isStringArray(expressions)) {
            return strs.reduce((acc, str, i) => `${acc}${t(str)}${t(expressions[i])}`, "")
          }

          // @ts-ignore
          return strs.reduce<React.ReactNode[]>((acc, str, i) => [...acc, t(str), expressions[i]], [])
        },
      }

  function t(str: string) {
    if (!str) return ""
    if (!group) return str
    if (!context.locale) return str

    // handle punctuation and extraneous pieces of sentences
    if (!str.match(/\w/)) return str
    // remove pre/post space and carriage returns
    const phrase = str.replace(/^[^a-zA-Z]+|[^a-zA-Z]+$/g, ``)
    const lang = context.locale.name

    if (!hasKey(DATA[group], phrase) || !hasKey(DATA[group][phrase], lang)) {
      if (lang !== "en")
        console.warn(`You tried to translate a phrase that is not in the dictionary: "${group}.${phrase}.${lang}"`)
      return str
    }

    return str.replace(phrase, DATA[group][phrase][lang])

    function hasKey(o: object, k: string): k is keyof object {
      return !!o && k in o
    }
  }
}
